import React from "react";
import { MDBFooter } from "mdb-react-ui-kit";
import styled from "styled-components";
import { defaultColor } from "../constants";

const FooterWrapper = styled.div`
  .footer {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 70px;
    display: flex;
    justify-content: space-between; /* Changed from start to space-between for balanced layout */
    align-items: center;
    background-color: ${process.env.REACT_APP_PUBLISHER_COLOR || defaultColor};
    padding: 0 20px; /* Added padding for better spacing */
  }
`;

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <FooterWrapper>
      <MDBFooter className="footer text-white">
        <div>&#169; Copyright 2022 - {currentYear} STM Solutions</div>
        <div>
          <a
            href="https://www.hub.stm-solutions.org/cookiepolicy"
            className="text-white"
            target="_blank"
            rel="noopener noreferrer"
          >
            Cookie Policy
          </a>
        </div>
      </MDBFooter>
    </FooterWrapper>
  );
};

export default Footer;
